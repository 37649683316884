/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  Amounts,
  MerchantTemplateContractDetails,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { InputDuration } from "../../../../components/form/InputDuration.js";
import { InputNumber } from "../../../../components/form/InputNumber.js";
import { InputWithAddon } from "../../../../components/form/InputWithAddon.js";
import { useBackendContext } from "../../../../context/backend.js";
import { MerchantBackend, WithId } from "../../../../declaration.js";
import { undefinedIfEmpty } from "../../../../utils/table.js";

type Entity = MerchantBackend.Template.TemplatePatchDetails & WithId;

interface Props {
  onUpdate: (d: Entity) => Promise<void>;
  onBack?: () => void;
  template: Entity;
}

export function UpdatePage({ template, onUpdate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { url: backendURL } = useBackendContext()

  const [state, setState] = useState<Partial<Entity>>(template);

  const parsedPrice = !state.template_contract?.amount
    ? undefined
    : Amounts.parse(state.template_contract?.amount);

  const errors: FormErrors<Entity> = {
    template_description: !state.template_description
      ? i18n.str`should not be empty`
      : undefined,
    template_contract: !state.template_contract
      ? undefined
      : undefinedIfEmpty({
        amount: !state.template_contract?.amount
          ? undefined
          : !parsedPrice
            ? i18n.str`not valid`
            : Amounts.isZero(parsedPrice)
              ? i18n.str`must be greater than 0`
              : undefined,
        minimum_age:
          state.template_contract.minimum_age < 0
            ? i18n.str`should be greater that 0`
            : undefined,
        pay_duration: !state.template_contract.pay_duration
          ? i18n.str`can't be empty`
          : state.template_contract.pay_duration.d_us === "forever"
            ? undefined
            : state.template_contract.pay_duration.d_us < 1000 * 1000 // less than one second
              ? i18n.str`to short`
              : undefined,
      } as Partial<MerchantTemplateContractDetails>),
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onUpdate(state as any);
  };


  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    {backendURL}/templates/{template.id}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider
                object={state}
                valueHandler={setState}
                errors={errors}
              >
                <InputWithAddon<Entity>
                  name="id"
                  addonBefore={`templates/`}
                  readonly
                  label={i18n.str`Identifier`}
                  tooltip={i18n.str`Name of the template in URLs.`}
                />

                <Input<Entity>
                  name="template_description"
                  label={i18n.str`Description`}
                  help=""
                  tooltip={i18n.str`Describe what this template stands for`}
                />
                <Input
                  name="template_contract.summary"
                  inputType="multiline"
                  label={i18n.str`Fixed summary`}
                  tooltip={i18n.str`If specified, this template will create order with the same summary`}
                />
                <InputCurrency
                  name="template_contract.amount"
                  label={i18n.str`Fixed price`}
                  tooltip={i18n.str`If specified, this template will create order with the same price`}
                />
                <InputNumber
                  name="template_contract.minimum_age"
                  label={i18n.str`Minimum age`}
                  help=""
                  tooltip={i18n.str`Is this contract restricted to some age?`}
                />
                <InputDuration
                  name="template_contract.pay_duration"
                  label={i18n.str`Payment timeout`}
                  help=""
                  tooltip={i18n.str`How much time has the customer to complete the payment once the order was created.`}
                />
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={hasErrors}
                  data-tooltip={
                    hasErrors
                      ? i18n.str`Need to complete marked fields`
                      : "confirm operation"
                  }
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
