/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { StateUpdater, useState } from "preact/hooks";
import { MerchantBackend } from "../../../../declaration.js";

type Entity = MerchantBackend.OTP.OtpDeviceEntry;

interface Props {
  devices: Entity[];
  onDelete: (e: Entity) => void;
  onSelect: (e: Entity) => void;
  onCreate: () => void;
  onLoadMoreBefore?: () => void;
  hasMoreBefore?: boolean;
  hasMoreAfter?: boolean;
  onLoadMoreAfter?: () => void;
}

export function CardTable({
  devices,
  onCreate,
  onDelete,
  onSelect,
  onLoadMoreAfter,
  onLoadMoreBefore,
  hasMoreAfter,
  hasMoreBefore,
}: Props): VNode {
  const [rowSelection, rowSelectionHandler] = useState<string[]>([]);

  const { i18n } = useTranslationContext();

  return (
    <div class="card has-table">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon">
            <i class="mdi mdi-newspaper" />
          </span>
          <i18n.Translate>OTP Devices</i18n.Translate>
        </p>
        <div class="card-header-icon" aria-label="more options">
          <span
            class="has-tooltip-left"
            data-tooltip={i18n.str`add new devices`}
          >
            <button class="button is-info" type="button" onClick={onCreate}>
              <span class="icon is-small">
                <i class="mdi mdi-plus mdi-36px" />
              </span>
            </button>
          </span>
        </div>
      </header>
      <div class="card-content">
        <div class="b-table has-pagination">
          <div class="table-wrapper has-mobile-cards">
            {devices.length > 0 ? (
              <Table
                instances={devices}
                onDelete={onDelete}
                onSelect={onSelect}
                rowSelection={rowSelection}
                rowSelectionHandler={rowSelectionHandler}
                onLoadMoreAfter={onLoadMoreAfter}
                onLoadMoreBefore={onLoadMoreBefore}
                hasMoreAfter={hasMoreAfter}
                hasMoreBefore={hasMoreBefore}
              />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
interface TableProps {
  rowSelection: string[];
  instances: Entity[];
  onDelete: (e: Entity) => void;
  onSelect: (e: Entity) => void;
  rowSelectionHandler: StateUpdater<string[]>;
  onLoadMoreBefore?: () => void;
  hasMoreBefore?: boolean;
  hasMoreAfter?: boolean;
  onLoadMoreAfter?: () => void;
}

function toggleSelected<T>(id: T): (prev: T[]) => T[] {
  return (prev: T[]): T[] =>
    prev.indexOf(id) == -1 ? [...prev, id] : prev.filter((e) => e != id);
}

function Table({
  instances,
  onLoadMoreAfter,
  onDelete,
  onSelect,
  onLoadMoreBefore,
  hasMoreAfter,
  hasMoreBefore,
}: TableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      {onLoadMoreBefore && (
        <button
          class="button is-fullwidth"
          data-tooltip={i18n.str`load more devices before the first one`}
          disabled={!hasMoreBefore}
          onClick={onLoadMoreBefore}
        >
          <i18n.Translate>load newer devices</i18n.Translate>
        </button>
      )}
      <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>ID</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Description</i18n.Translate>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {instances.map((i) => {
            return (
              <tr key={i.otp_device_id}>
                <td
                  onClick={(): void => onSelect(i)}
                  style={{ cursor: "pointer" }}
                >
                  {i.otp_device_id}
                </td>
                <td
                  onClick={(): void => onSelect(i)}
                  style={{ cursor: "pointer" }}
                >
                  {i.otp_device_id}
                </td>
                <td class="is-actions-cell right-sticky">
                  <div class="buttons is-right">
                    <button
                      class="button is-danger is-small has-tooltip-left"
                      data-tooltip={i18n.str`delete selected devices from the database`}
                      onClick={() => onDelete(i)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {onLoadMoreAfter && (
        <button
          class="button is-fullwidth"
          data-tooltip={i18n.str`load more devices after the last one`}
          disabled={!hasMoreAfter}
          onClick={onLoadMoreAfter}
        >
          <i18n.Translate>load older devices</i18n.Translate>
        </button>
      )}
    </div>
  );
}

function EmptyTable(): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="content has-text-grey has-text-centered">
      <p>
        <span class="icon is-large">
          <i class="mdi mdi-emoticon-sad mdi-48px" />
        </span>
      </p>
      <p>
        <i18n.Translate>
          There is no devices yet, add more pressing the + sign
        </i18n.Translate>
      </p>
    </div>
  );
}
