/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { parsePaytoUri, stringifyPaytoUri } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { QR } from "../../../../components/exception/QR.js";
import { CreatedSuccessfully as Template } from "../../../../components/notifications/CreatedSuccessfully.js";
import { MerchantBackend, WireAccount } from "../../../../declaration.js";

type Entity = {
  request: MerchantBackend.Rewards.ReserveCreateRequest;
  response: MerchantBackend.Rewards.ReserveCreateConfirmation;
};

interface Props {
  entity: Entity;
  onConfirm: () => void;
  onCreateAnother?: () => void;
}

function isNotUndefined<X>(x: X | undefined): x is X {
  return !!x;
}

export function CreatedSuccessfully({
  entity,
  onConfirm,
  onCreateAnother,
}: Props): VNode {
  const { i18n } = useTranslationContext();
  return (
    <Template onConfirm={onConfirm} onCreateAnother={onCreateAnother}>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Amount</label>
        </div>
        <div class="field-body is-flex-grow-3">
          <div class="field">
            <p class="control">
              <input
                readonly
                class="input"
                value={entity.request.initial_balance}
              />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Wire transfer subject</label>
        </div>
        <div class="field-body is-flex-grow-3">
          <div class="field">
            <p class="control">
              <input
                class="input"
                readonly
                value={entity.response.reserve_pub}
              />
            </p>
          </div>
        </div>
      </div>
      <ShowAccountsOfReserveAsQRWithLink
        accounts={entity.response.accounts ?? []}
        message={entity.response.reserve_pub}
        amount={entity.request.initial_balance}
      />
    </Template>
  );
}

export function ShowAccountsOfReserveAsQRWithLink({
  accounts,
  message,
  amount,
}: {
  accounts: WireAccount[];
  message: string;
  amount: string;
}): VNode {
  const { i18n } = useTranslationContext();
  const accountsInfo = !accounts
    ? []
    : accounts
      .map((acc) => {
        const p = parsePaytoUri(acc.payto_uri);
        if (p) {
          p.params["message"] = message;
          p.params["amount"] = amount;
        }
        return p;
      })
      .filter(isNotUndefined);

  const links = accountsInfo.map((a) => stringifyPaytoUri(a));

  if (links.length === 0) {
    return (
      <Fragment>
        <p class="is-size-5">
          The reserve have invalid accounts. List of invalid payto URIs below:
        </p>
        <ul>
          {accounts.map((a, idx) => {
            return <li key={idx}>{a.payto_uri}</li>;
          })}
        </ul>
      </Fragment>
    );
  }

  if (links.length === 1) {
    return (
      <Fragment>
        <p class="is-size-5">
          <i18n.Translate>
            To complete the setup of the reserve, you must now initiate a wire
            transfer using the given wire transfer subject and crediting the
            specified amount to the indicated account of the exchange.
          </i18n.Translate>
        </p>
        <p style={{ margin: 10 }}>
          <b>Exchange bank account</b>
        </p>
        <QR text={links[0]} />
        <p class="is-size-5">
          <i18n.Translate>
            If your system supports RFC 8905, you can do this by opening this
            URI:
          </i18n.Translate>
        </p>
        <pre>
          <a target="_blank" rel="noreferrer" href={links[0]}>
            {links[0]}
          </a>
        </pre>
      </Fragment>
    );
  }

  return (
    <div>
      <p class="is-size-5">
        <i18n.Translate>
          To complete the setup of the reserve, you must now initiate a wire
          transfer using the given wire transfer subject and crediting the
          specified amount to one of the indicated account of the exchange.
        </i18n.Translate>
      </p>

      <p style={{ margin: 10 }}>
        <b>Exchange bank accounts</b>
      </p>
      <p class="is-size-5">
        <i18n.Translate>
          If your system supports RFC 8905, you can do this by clicking on the
          URI below the QR code:
        </i18n.Translate>
      </p>
      {links.map((link) => {
        return (
          <Fragment>
            <QR text={link} />
            <pre>
              <a target="_blank" rel="noreferrer" href={link}>
                {link}
              </a>
            </pre>
          </Fragment>
        );
      })}
    </div>
  );
}
