/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import {
  useInstanceReserves,
  useReservesAPI,
} from "../../../../hooks/reserves.js";
import { Notification } from "../../../../utils/types.js";
import { AuthorizeRewardModal } from "./AutorizeRewardModal.js";
import { CardTable } from "./Table.js";
import { HttpStatusCode } from "@gnu-taler/taler-util";
import { ConfirmModal } from "../../../../components/modal/index.js";

interface Props {
  onUnauthorized: () => VNode;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  onSelect: (id: string) => void;
  onNotFound: () => VNode;
  onCreate: () => void;
}

interface RewardConfirmation {
  response: MerchantBackend.Rewards.RewardCreateConfirmation;
  request: MerchantBackend.Rewards.RewardCreateRequest;
}

export default function ListRewards({
  onUnauthorized,
  onLoadError,
  onNotFound,
  onSelect,
  onCreate,
}: Props): VNode {
  const result = useInstanceReserves();
  const { deleteReserve, authorizeRewardReserve } = useReservesAPI();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const [reserveForReward, setReserveForReward] = useState<string | undefined>(
    undefined,
  );
  const [deleting, setDeleting] =
    useState<MerchantBackend.Rewards.ReserveStatusEntry | null>(null);
  const [rewardAuthorized, setRewardAuthorized] = useState<
    RewardConfirmation | undefined
  >(undefined);

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      {reserveForReward && (
        <AuthorizeRewardModal
          onCancel={() => {
            setReserveForReward(undefined);
            setRewardAuthorized(undefined);
          }}
          rewardAuthorized={rewardAuthorized}
          onConfirm={async (request) => {
            try {
              const response = await authorizeRewardReserve(
                reserveForReward,
                request,
              );
              setRewardAuthorized({
                request,
                response: response.data,
              });
            } catch (error) {
              setNotif({
                message: i18n.str`could not create the reward`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
              setReserveForReward(undefined);
            }
          }}
        />
      )}

      <CardTable
        instances={result.data.reserves
          .filter((r) => r.active)
          .map((o) => ({ ...o, id: o.reserve_pub }))}
        onCreate={onCreate}
        onDelete={(reserve) => {
          setDeleting(reserve)
        }}
        onSelect={(reserve) => onSelect(reserve.id)}
        onNewReward={(reserve) => setReserveForReward(reserve.id)}
      />

      {deleting && (
        <ConfirmModal
          label={`Delete reserve`}
          description={`Delete the reserve`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await deleteReserve(deleting.reserve_pub);
              setNotif({
                message: i18n.str`Reserve for "${deleting.merchant_initial_amount}" (ID: ${deleting.reserve_pub}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete reserve`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the reserve for <b>&quot;{deleting.merchant_initial_amount}&quot;</b> you won't be able to create more rewards. <br />
            Reserve ID: <b>{deleting.reserve_pub}</b>
          </p>
          <p class="warning">
            Deleting an template <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}

    </section>
  );
}
