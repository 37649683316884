/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  HttpResponse,
  HttpResponseOk,
  RequestError,
} from "@gnu-taler/web-util/browser";
import { MerchantBackend } from "../declaration.js";
import { useBackendInstanceRequest, useMatchMutate } from "./backend.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook, useSWRConfig } from "swr";
const useSWR = _useSWR as unknown as SWRHook;

export function useReservesAPI(): ReserveMutateAPI {
  const mutateAll = useMatchMutate();
  const { mutate } = useSWRConfig();
  const { request } = useBackendInstanceRequest();

  const createReserve = async (
    data: MerchantBackend.Rewards.ReserveCreateRequest,
  ): Promise<
    HttpResponseOk<MerchantBackend.Rewards.ReserveCreateConfirmation>
  > => {
    const res = await request<MerchantBackend.Rewards.ReserveCreateConfirmation>(
      `/private/reserves`,
      {
        method: "POST",
        data,
      },
    );

    //evict reserve list query
    await mutateAll(/.*private\/reserves.*/);

    return res;
  };

  const authorizeRewardReserve = async (
    pub: string,
    data: MerchantBackend.Rewards.RewardCreateRequest,
  ): Promise<HttpResponseOk<MerchantBackend.Rewards.RewardCreateConfirmation>> => {
    const res = await request<MerchantBackend.Rewards.RewardCreateConfirmation>(
      `/private/reserves/${pub}/authorize-reward`,
      {
        method: "POST",
        data,
      },
    );

    //evict reserve details query
    await mutate([`/private/reserves/${pub}`]);

    return res;
  };

  const authorizeReward = async (
    data: MerchantBackend.Rewards.RewardCreateRequest,
  ): Promise<HttpResponseOk<MerchantBackend.Rewards.RewardCreateConfirmation>> => {
    const res = await request<MerchantBackend.Rewards.RewardCreateConfirmation>(
      `/private/rewards`,
      {
        method: "POST",
        data,
      },
    );

    //evict all details query
    await mutateAll(/.*private\/reserves\/.*/);

    return res;
  };

  const deleteReserve = async (
    pub: string,
  ): Promise<HttpResponse<void, MerchantBackend.ErrorDetail>> => {
    const res = await request<void>(`/private/reserves/${pub}`, {
      method: "DELETE",
    });

    //evict reserve list query
    await mutateAll(/.*private\/reserves.*/);

    return res;
  };

  return { createReserve, authorizeReward, authorizeRewardReserve, deleteReserve };
}

export interface ReserveMutateAPI {
  createReserve: (
    data: MerchantBackend.Rewards.ReserveCreateRequest,
  ) => Promise<HttpResponseOk<MerchantBackend.Rewards.ReserveCreateConfirmation>>;
  authorizeRewardReserve: (
    id: string,
    data: MerchantBackend.Rewards.RewardCreateRequest,
  ) => Promise<HttpResponseOk<MerchantBackend.Rewards.RewardCreateConfirmation>>;
  authorizeReward: (
    data: MerchantBackend.Rewards.RewardCreateRequest,
  ) => Promise<HttpResponseOk<MerchantBackend.Rewards.RewardCreateConfirmation>>;
  deleteReserve: (
    id: string,
  ) => Promise<HttpResponse<void, MerchantBackend.ErrorDetail>>;
}

export function useInstanceReserves(): HttpResponse<
  MerchantBackend.Rewards.RewardReserveStatus,
  MerchantBackend.ErrorDetail
> {
  const { fetcher } = useBackendInstanceRequest();

  const { data, error, isValidating } = useSWR<
    HttpResponseOk<MerchantBackend.Rewards.RewardReserveStatus>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/reserves`], fetcher);

  if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

export function useReserveDetails(
  reserveId: string,
): HttpResponse<
  MerchantBackend.Rewards.ReserveDetail,
  MerchantBackend.ErrorDetail
> {
  const { reserveDetailFetcher } = useBackendInstanceRequest();

  const { data, error, isValidating } = useSWR<
    HttpResponseOk<MerchantBackend.Rewards.ReserveDetail>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/reserves/${reserveId}`], reserveDetailFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

export function useRewardDetails(
  rewardId: string,
): HttpResponse<MerchantBackend.Rewards.RewardDetails, MerchantBackend.ErrorDetail> {
  const { rewardsDetailFetcher } = useBackendInstanceRequest();

  const { data, error, isValidating } = useSWR<
    HttpResponseOk<MerchantBackend.Rewards.RewardDetails>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/rewards/${rewardId}`], rewardsDetailFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}
