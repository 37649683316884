/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { InputDuration } from "../../../../components/form/InputDuration.js";
import { InputNumber } from "../../../../components/form/InputNumber.js";
import { useBackendContext } from "../../../../context/backend.js";
import { MerchantBackend } from "../../../../declaration.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";

type Entity = MerchantBackend.Webhooks.WebhookAddDetails;

interface Props {
  onCreate: (d: Entity) => Promise<void>;
  onBack?: () => void;
}

const validMethod = ["GET", "POST", "PUT", "PATCH", "HEAD"];

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>({});

  const errors: FormErrors<Entity> = {
    webhook_id: !state.webhook_id ? i18n.str`required` : undefined,
    event_type: !state.event_type ? i18n.str`required` 
    : state.event_type !== "pay" && state.event_type !== "refund" ? i18n.str`it should be "pay" or "refund"`
    : undefined,
    http_method: !state.http_method
      ? i18n.str`required`
      : !validMethod.includes(state.http_method)
      ? i18n.str`should be one of '${validMethod.join(", ")}'`
      : undefined,
    url: !state.url ? i18n.str`required` : undefined,
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onCreate(state as any);
  };

  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <Input<Entity>
                name="webhook_id"
                label={i18n.str`ID`}
                tooltip={i18n.str`Webhook ID to use`}
              />
              <InputSelector
                name="event_type"
                label={i18n.str`Event`}
                values={[
                  i18n.str`Choose one...`,
                  i18n.str`pay`,
                  i18n.str`refund`,
                ]}
                tooltip={i18n.str`The event of the webhook: why the webhook is used`}
              />
              <InputSelector
                name="http_method"
                label={i18n.str`Method`}
                values={[
                  i18n.str`Choose one...`,
                  i18n.str`GET`,
                  i18n.str`POST`,
                  i18n.str`PUT`,
                  i18n.str`PATCH`,
                  i18n.str`HEAD`,
                ]}
                tooltip={i18n.str`Method used by the webhook`}
              />

              <Input<Entity>
                name="url"
                label={i18n.str`URL`}
                tooltip={i18n.str`URL of the webhook where the customer will be redirected`}
              />
              <Input<Entity>
                name="header_template"
                label={i18n.str`Header`}
                inputType="multiline"
                tooltip={i18n.str`Header template of the webhook`}
              />
              <Input<Entity>
                name="body_template"
                inputType="multiline"
                label={i18n.str`Body`}
                tooltip={i18n.str`Body template by the webhook`}
              />
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : "confirm operation"
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
