/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import * as yup from "yup";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import {
  ConfirmModal,
  ContinueModal,
} from "../../../../components/modal/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import { AuthorizeRewardSchema } from "../../../../schemas/index.js";
import { CreatedSuccessfully } from "./CreatedSuccessfully.js";

interface AuthorizeRewardModalProps {
  onCancel: () => void;
  onConfirm: (value: MerchantBackend.Rewards.RewardCreateRequest) => void;
  rewardAuthorized?: {
    response: MerchantBackend.Rewards.RewardCreateConfirmation;
    request: MerchantBackend.Rewards.RewardCreateRequest;
  };
}

export function AuthorizeRewardModal({
  onCancel,
  onConfirm,
  rewardAuthorized,
}: AuthorizeRewardModalProps): VNode {
  // const result = useOrderDetails(id)
  type State = MerchantBackend.Rewards.RewardCreateRequest;
  const [form, setValue] = useState<Partial<State>>({});
  const { i18n } = useTranslationContext();

  // const [errors, setErrors] = useState<FormErrors<State>>({})
  let errors: FormErrors<State> = {};
  try {
    AuthorizeRewardSchema.validateSync(form, { abortEarly: false });
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const yupErrors = err.inner as any[];
      errors = yupErrors.reduce(
        (prev, cur) =>
          !cur.path ? prev : { ...prev, [cur.path]: cur.message },
        {},
      );
    }
  }
  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const validateAndConfirm = () => {
    onConfirm(form as State);
  };
  if (rewardAuthorized) {
    return (
      <ContinueModal description="reward" active onConfirm={onCancel}>
        <CreatedSuccessfully
          entity={rewardAuthorized.response}
          request={rewardAuthorized.request}
          onConfirm={onCancel}
        />
      </ContinueModal>
    );
  }

  return (
    <ConfirmModal
      description="New reward"
      active
      onCancel={onCancel}
      disabled={hasErrors}
      onConfirm={validateAndConfirm}
    >
      <FormProvider<State>
        errors={errors}
        object={form}
        valueHandler={setValue}
      >
        <InputCurrency<State>
          name="amount"
          label={i18n.str`Amount`}
          tooltip={i18n.str`amount of reward`}
        />
        <Input<State>
          name="justification"
          label={i18n.str`Justification`}
          inputType="multiline"
          tooltip={i18n.str`reason for the reward`}
        />
        <Input<State>
          name="next_url"
          label={i18n.str`URL after reward`}
          tooltip={i18n.str`URL to visit after reward payment`}
        />
      </FormProvider>
    </ConfirmModal>
  );
}
