/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { HttpStatusCode } from "@gnu-taler/taler-util";
import { ErrorType, HttpError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { Loading } from "../../../components/exception/loading.js";
import { AccessToken, MerchantBackend } from "../../../declaration.js";
import { useInstanceAPI, useInstanceDetails } from "../../../hooks/instance.js";
import { DetailPage } from "./DetailPage.js";
import { useInstanceContext } from "../../../context/instance.js";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../components/menu/index.js";
import { Notification } from "../../../utils/types.js";
import { useBackendContext } from "../../../context/backend.js";

interface Props {
  onUnauthorized: () => VNode;
  onLoadError: (error: HttpError<MerchantBackend.ErrorDetail>) => VNode;
  onChange: () => void;
  onNotFound: () => VNode;
}

export default function Token({
  onLoadError,
  onChange,
  onUnauthorized,
  onNotFound,
}: Props): VNode {
  const { i18n } = useTranslationContext();

  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { clearAccessToken, setNewAccessToken } = useInstanceAPI();
  const { id } = useInstanceContext();
  const result = useInstanceDetails()

  if (result.loading) return <Loading />;
  if (!result.ok) {
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.Unauthorized
    )
      return onUnauthorized();
    if (
      result.type === ErrorType.CLIENT &&
      result.status === HttpStatusCode.NotFound
    )
      return onNotFound();
    return onLoadError(result);
  }

  const hasToken = result.data.auth.method === "token"

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <DetailPage
        instanceId={id}
        hasToken={hasToken}
        onClearToken={async (currentToken): Promise<void> => {
          try {
            await clearAccessToken(currentToken);
            onChange();
          } catch (error) {
            if (error instanceof Error) {
              setNotif({
                message: i18n.str`Failed to clear token`,
                type: "ERROR",
                description: error.message,
              });
            }
          }
        }}
        onNewToken={async (currentToken, newToken): Promise<void> => {
          try {
            await setNewAccessToken(currentToken, newToken);
            onChange();
          } catch (error) {
            if (error instanceof Error) {
              setNotif({
                message: i18n.str`Failed to set new token`,
                type: "ERROR",
                description: error.message,
              });
            }
          }
        }}
      />
    </Fragment>
  );
}
