/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  HttpResponse,
  HttpResponseOk,
  HttpResponsePaginated,
  RequestError,
} from "@gnu-taler/web-util/browser";
import { useEffect, useState } from "preact/hooks";
import { MerchantBackend } from "../declaration.js";
import { MAX_RESULT_SIZE, PAGE_SIZE } from "../utils/constants.js";
import { useBackendInstanceRequest, useMatchMutate } from "./backend.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook } from "swr";
const useSWR = _useSWR as unknown as SWRHook;

export function useWebhookAPI(): WebhookAPI {
  const mutateAll = useMatchMutate();
  const { request } = useBackendInstanceRequest();

  const createWebhook = async (
    data: MerchantBackend.Webhooks.WebhookAddDetails,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/webhooks`, {
      method: "POST",
      data,
    });
    await mutateAll(/.*private\/webhooks.*/);
    return res;
  };

  const updateWebhook = async (
    webhookId: string,
    data: MerchantBackend.Webhooks.WebhookPatchDetails,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/webhooks/${webhookId}`, {
      method: "PATCH",
      data,
    });
    await mutateAll(/.*private\/webhooks.*/);
    return res;
  };

  const deleteWebhook = async (
    webhookId: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`/private/webhooks/${webhookId}`, {
      method: "DELETE",
    });
    await mutateAll(/.*private\/webhooks.*/);
    return res;
  };

  return { createWebhook, updateWebhook, deleteWebhook };
}

export interface WebhookAPI {
  createWebhook: (
    data: MerchantBackend.Webhooks.WebhookAddDetails,
  ) => Promise<HttpResponseOk<void>>;
  updateWebhook: (
    id: string,
    data: MerchantBackend.Webhooks.WebhookPatchDetails,
  ) => Promise<HttpResponseOk<void>>;
  deleteWebhook: (id: string) => Promise<HttpResponseOk<void>>;
}

export interface InstanceWebhookFilter {
  //FIXME: add filter to the webhook list
  position?: string;
}

export function useInstanceWebhooks(
  args?: InstanceWebhookFilter,
  updatePosition?: (id: string) => void,
): HttpResponsePaginated<
  MerchantBackend.Webhooks.WebhookSummaryResponse,
  MerchantBackend.ErrorDetail
> {
  const { webhookFetcher } = useBackendInstanceRequest();

  const [pageAfter, setPageAfter] = useState(1);

  const totalAfter = pageAfter * PAGE_SIZE;

  const {
    data: afterData,
    error: afterError,
    isValidating: loadingAfter,
  } = useSWR<
    HttpResponseOk<MerchantBackend.Webhooks.WebhookSummaryResponse>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/webhooks`, args?.position, -totalAfter], webhookFetcher);

  const [lastAfter, setLastAfter] = useState<
    HttpResponse<
      MerchantBackend.Webhooks.WebhookSummaryResponse,
      MerchantBackend.ErrorDetail
    >
  >({ loading: true });
  useEffect(() => {
    if (afterData) setLastAfter(afterData);
  }, [afterData]);

  if (afterError) return afterError.cause;

  const isReachingEnd =
    afterData && afterData.data.webhooks.length < totalAfter;
  const isReachingStart = false;

  const pagination = {
    isReachingEnd,
    isReachingStart,
    loadMore: () => {
      if (!afterData || isReachingEnd) return;
      if (afterData.data.webhooks.length < MAX_RESULT_SIZE) {
        setPageAfter(pageAfter + 1);
      } else {
        const from = `${
          afterData.data.webhooks[afterData.data.webhooks.length - 1].webhook_id
        }`;
        if (from && updatePosition) updatePosition(from);
      }
    },
    loadMorePrev: () => {
      return;
    },
  };

  const webhooks = !afterData ? [] : (afterData || lastAfter).data.webhooks;

  if (loadingAfter) return { loading: true, data: { webhooks } };
  if (afterData) {
    return { ok: true, data: { webhooks }, ...pagination };
  }
  return { loading: true };
}

export function useWebhookDetails(
  webhookId: string,
): HttpResponse<
  MerchantBackend.Webhooks.WebhookDetails,
  MerchantBackend.ErrorDetail
> {
  const { webhookFetcher } = useBackendInstanceRequest();

  const { data, error, isValidating } = useSWR<
    HttpResponseOk<MerchantBackend.Webhooks.WebhookDetails>,
    RequestError<MerchantBackend.ErrorDetail>
  >([`/private/webhooks/${webhookId}`], webhookFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}
